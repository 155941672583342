import { PaginateParams, MetaData } from './global';

// data response
export class Reward {
    logo?: string;
    logoUrl?: string;
    bannerUrl?: string;
    banner?: string;
    endedAt?: string | Date = new Date();
    id: number;
    point?: number;
    remain_days?: number;
    startedAt?: string | Date = new Date();
    status?: string;
    title?: string;
    maxRedeem?: number;
    qty?: number;
    description?: string;
    name?: string;
    redeemedAt?: string | Date;
    unlimitedQty?: false;
    updatedAt?: string | Date = new Date();
    updatedBy?: string;
    remainingQty?: number;
    isAutoApproved?: boolean;
    pointTransactionId?: number;
    isAlwaysAvailable?: boolean;
}
//////////////////////////////////////////

// API response
export interface ResponseReward {
    data: Reward[];
    metadata: MetaData;
}

export interface ResponseRewardDetail {
    data: Reward;
}

// service params
export interface GetRewardParams extends PaginateParams {
    startDate?: string;
    endDate?: string;
}

export interface GetRewardHistoryParmas extends PaginateParams {
    employeeID?: number;
    startedAt?: string;
    endedAt?: string;
    rewardId?: number;
}

export interface ExportHistoryParams extends PaginateParams {
    rewardId?: number;
    startedAt?: string;
    endedAt?: string;
}
/////////////////////////////////////////////////////////////
