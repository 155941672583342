import { AbstractControl } from '@angular/forms';

export function ValidateSpace(control: AbstractControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    // const isValid = !isWhitespace;
    if (isWhitespace) {
        return { validateSpace: true };
    }
    return false;
}
