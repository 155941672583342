import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { PaginateParams } from '../model/global';

@Injectable({
  providedIn: 'root'
})
export class ResellerService {
  endPoint = this._globalService.apiUrl;
      // version 2
  endPointV2 = this._globalService.apiUrlV2;

  constructor(
    private _globalService: GlobalService, 
    private _http: HttpClient
    ) { }

    getresellerList(params: PaginateParams) {
      return this._http.get(`${this.endPointV2}/Reseller`, {
          headers: this._globalService.getHeader(),
          params: this._globalService.reGenerateParams(params)
      });
    }
      // update organization
    updateUser(id: number, body) {
      return this._http.put(`${this.endPointV2}`, body, {
          headers: this._globalService.getHeader()
      });
    }
      // add Form
    addReseller(body) {
      return this._http.post(`${this.endPointV2}/Reseller`, body, {
          headers: this._globalService.getHeader()
      });
    }
    getDetail(id: number) {
      return this._http.get(`${this.endPointV2}/Reseller/${id}`, {
          headers: this._globalService.getHeader()
      });
    }
     // update organization
    updateReseller(id: number, body) {
      return this._http.put(`${this.endPointV2}/Reseller/${id}`, body, {
          headers: this._globalService.getHeader()
      });
    }
    toggleStatus(id: number, body) {
      return this._http.request('delete', this.endPointV2 + `/Reseller/${id}`, {
          body: body,
          headers: this._globalService.getHeader()
      });
      // return this.http.delete(this.url + this.ProducGrouptUrl + `/${id}`, { headers: this.gbService.getHeader() });
   }
  //   toggleStatus(id: number) {
  //     return this._http.delete(
  //         `${this.endPointV2}/Reseller/${id}`,
  //         {
  //           headers: this._globalService.getHeader()
  //         }
  //     );
  // }
  getreselleruserlist(params: PaginateParams) {
    return this._http.get(`${this.endPointV2}/reseller/users`, {
        headers: this._globalService.getHeader(),
        params: this._globalService.reGenerateParams(params)
    });
  }
  getreselleruserDetail(id: number) {
    return this._http.get(`${this.endPointV2}/reseller/users/${id}`, {
        headers: this._globalService.getHeader()
    });
  }
}
