import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/app/shared/services/global.service';
import { GetBadgeParams } from '../model/badge';
import { PaginateParams } from '../model/global';
import { BehaviorSubject } from 'rxjs';
import { CurrentUserService } from 'src/app/shared/services/current-user.service';

@Injectable({
    providedIn: 'root'
})
export class BadgeService {
    header = this._globalService.getHeader();
    // endPoint = this._globalService.apiUrl;
    endPoint = this._globalService.apiUrlV2;
    badgePosition = new BehaviorSubject('');

    organizationId: number = this._currentUser.profile.organizationId;

    constructor(
        private _globalService: GlobalService,
        private _http: HttpClient,
        private _currentUser: CurrentUserService
    ) {}

    // get badge list
    // getBadge(params: GetBadgeParams) {
    //     return this._http.get(`${this.endPoint}/badge`, {
    //         headers: this.header,
    //         params: this._globalService.reGenerateParams(params)
    //     });
    // }

    // get badge Image list
    getBadgeImage(params: PaginateParams) {
        return this._http.get(`${this.endPoint}/badge/image`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params)
        });
    }

    // get organization list
    getOrganization(params: PaginateParams) {
        return this._http.get(`${this.endPoint}/organization`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params)
        });
    }

    // get badgeCategory list
    getBadgeCategory(params: PaginateParams) {
        return this._http.get(`${this.endPoint}/badge-category`, {
            headers: this.header,
            params: this._globalService.reGenerateParams(params)
        });
    }

    // add badge
    // addBadge(body) {
    //     return this._http.post(`${this.endPoint}/badge`, body, {
    //         headers: this.header
    //     });
    // }

    // get badge detail
    // getBadgeDetail(id: number) {
    //     return this._http.get(`${this.endPoint}/badge/${id}`, {
    //         headers: this.header
    //     });
    // }

    // update badge
    // updateBadge(id: number, body) {
    //     return this._http.put(`${this.endPoint}/badge/${id}`, body, {
    //         headers: this.header
    //     });
    // }

    // set badge position
    setBadgePosition(value) {
        return this.badgePosition.next(value);
    }

    /**=======================
     * Version 2
     ========================*/
    // get badge list
    getBadge(params: GetBadgeParams) {
        return this._http.get(
            `${this.endPoint}/organization/${this.organizationId}/badge`,
            {
                headers: this.header,
                params: this._globalService.reGenerateParams(params)
            }
        );
    }

    // add badge
    addBadge(body) {
        return this._http.post(
            `${this.endPoint}/organization/${this.organizationId}/badge`,
            body,
            {
                headers: this.header
            }
        );
    }

    // get badge detail
    getBadgeDetail(id: number) {
        return this._http.get(
            `${this.endPoint}/organization/${this.organizationId}/badge/${id}`,
            {
                headers: this.header
            }
        );
    }

    // update badge
    updateBadge(id: number, body) {
        return this._http.put(
            `${this.endPoint}/organization/${this.organizationId}/badge/${id}`,
            body,
            {
                headers: this.header
            }
        );
    }

    assignBadge(id: number, body) {
        return this._http.post(
            `${this.endPoint}/organization/${this.organizationId}/badge/${id}/assign`,
            body,
            {
                headers: this.header
            }
        );
    }

    // assign badge on user list
    assignBadges(body) {
        return this._http.post(
            `${this.endPoint}/organization/${this.organizationId}/badge/assign`,
            body,
            {
                headers: this.header
            }
        );
    }

    // delete badge
    deleteBadge(id) {
        return this._http.delete(
            `${this.endPoint}/organization/${this.organizationId}/badge/${id}`,
            {
                headers: this.header
            }
        );
    }
}
