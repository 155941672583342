import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { PaginateParams } from '../model/global';
import { GetRewardParams } from '../model/reward';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  endPoint = this._globalService.apiUrl;
      // version 2
  endPointV2 = this._globalService.apiUrlV2;

  constructor(private _globalService: GlobalService, private _http: HttpClient) { }
  getleadList(params: PaginateParams) {
    return this._http.get(`${this.endPointV2}/lead`, {
        headers: this._globalService.getHeader(),
        params: this._globalService.reGenerateParams(params)
    });
  }
  // add lead
  addLead(body) {
      return this._http.post(`${this.endPointV2}/lead`, body, {
          headers: this._globalService.getHeader()
      });
  }
     // get organization detail
  getDetail(id: number) {
      return this._http.get(`${this.endPointV2}/lead/${id}`, {
          headers: this._globalService.getHeader()
      });
  }
  // update organization
  updateLead(id: number, body) {
    return this._http.put(`${this.endPointV2}/lead/${id}`, body, {
        headers: this._globalService.getHeader()
    });
  }
  // toggle organization
//   toggleStatus(id: number,body) {
//     return this._http.delete(`${this.endPoint}/organization/${id}`, {
//         headers: this._globalService.getHeader()
//     });
//   }
  toggleStatus(id: number, body) {
    return this._http.request('delete', this.endPointV2 + `/lead/${id}`, {
        body: body,
        headers: this._globalService.getHeader()
    });
    // return this.http.delete(this.url + this.ProducGrouptUrl + `/${id}`, { headers: this.gbService.getHeader() });
 }
  leadSumarry(params: PaginateParams) {
    return this._http.get(`${this.endPointV2}/lead/summary`, {
        headers: this._globalService.getHeader(),
        params: this._globalService.reGenerateParams(params)
    });
  }
  gethMonthlySubscrip(params?: Object, deleteFields?: string[]) {
    return this._http.get(`${this.endPointV2}/lead/subscription/summary`, {
        headers: this._globalService.getHeader(),
        params: this._globalService.reGenerateParams(params)
    });
  }
  gethMonthlySubDetail(id: number,params: PaginateParams) {
    return this._http.get(`${this.endPointV2}/lead/${id}/subscription`, {
        headers: this._globalService.getHeader(),
        params: this._globalService.reGenerateParams(params)
    });
  }
}
